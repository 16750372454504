<template>
  <Page mode="jacket">
    <h1>{{ labels.SignUp }}</h1>
    <p v-html="labels.Introduction"></p>

    <div class="vgs-form-indent selection" v-if="isFormShow">
      <span for="fm_mode" class="text-center w-100 font-weight-bold">{{ signUp.RegisterAs }}</span>
      <b-form-group id="fm_mode" label=" ">
        <b-form-radio
          v-model="formMode"
          name="fm-mode"
          value="natural"
          class="float-left"
          @change="onSelected"
          >{{ signUp.NaturalPerson }}</b-form-radio
        >
        <b-form-radio
          v-model="formMode"
          name="fm-mode"
          value="legal"
          class="float-right"
          @change="onSelected"
          >{{ signUp.LegalPerson }}</b-form-radio
        >
      </b-form-group>
    </div>

    <div class="vgs-form-indent">
      <SignUpNPForm
        v-bind:ElectronicDocumentDeliveryInfoText="labels.ElectronicDocumentDeliveryInfoText"
        v-if="formMode == 'natural'"
        @onSuccess="success"
      ></SignUpNPForm>
      <SignUpLPForm
        v-if="formMode == 'legal'"
        v-bind:ElectronicDocumentDeliveryInfoText="labels.ElectronicDocumentDeliveryInfoText"
        @onSuccess="success"
      ></SignUpLPForm>
    </div>

    <div class="vgs-form-indent text-left">
      <RouteLink route="signin" icon="arrow-right" :text="signIn.GoToSignIn"></RouteLink>
    </div>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue';
import SignUpNPForm from '@/forms/SignUpNPForm.vue';
import SignUpLPForm from '@/forms/SignUpLPForm.vue';
import RouteLink from '@/structure/RouteLink.vue';
import { vcm } from '@/mixins/vcm';
import { vvm } from '@/mixins/vvm';
import axios from 'axios';
import { customLabels } from '@/mixins/customLabels';

export default {
  mixins: [vcm, vvm, customLabels],
  name: 'SignUp',
  components: {
    Page,
    SignUpNPForm,
    SignUpLPForm,
    RouteLink,
  },
  data() {
    return {
      formMode: 'natural',
      isFormShow: true,
      labels: {
        SignUp: this.get_scope_translation('SignUp', 'label'),
        Introduction: this.get_scope_translation('Introduction', 'label'),
        ElectronicDocumentDeliveryInfoText: this.get_scope_translation('ElectronicDocumentDeliveryInfoText', 'label'),
      },
    };
  },
  mounted: function() {
    this.$root.set_document_title('route', '', '');
  },
  async beforeCreate() {
    try {
      const type = 'natural';
      const Url = process.env.VUE_APP_BASE_URL + `/v1/pages/signup/${type}`;
      console.log(Url);
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
      });
      if (response.data.responseCode === '200' && response.data.response) {
        this.labels.SignUp = response.data.response.Title;
        this.labels.Introduction = response.data.response.Description;
        this.labels.ElectronicDocumentDeliveryInfoText = response.data.response.ElectronicDocumentDeliveryInfoText;
      }
    } catch (e) {
      console.log('Error : ' + e);
    }
  },
  methods: {
    success(isShow) {
      this.isFormShow = isShow;
    },
    async onSelected() {
      try {
        const Url =
          process.env.VUE_APP_BASE_URL + `/v1/pages/signup/${this.formMode}`;
        console.log(Url);
        const response = await axios.get(Url, {
          params: { language: `${this.$root.config.localization.language}` },
        });
        if (response.data.responseCode === "200" && response.data.response) {
          this.labels.SignUp = response.data.response.Title;
          this.labels.Introduction = response.data.response.Description;
          this.labels.ElectronicDocumentDeliveryInfoText =
            response.data.response.ElectronicDocumentDeliveryInfoText;
        }
      } catch (e) {
        console.log("Error : " + e);
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.875rem;
  line-height: 2.563rem;
  /* color: #primaryColor; */
}
p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #000;
}
</style>
