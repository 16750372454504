<template>
  <div>
    <b-form v-if="isShow">
      <div class="position-relative" ref="municipality">
        <b-form-select
          id="fm_municipality"
          :aria-label="labels.Municipality"
          v-model="form.municipality"
          ref="municipality_form-control"
          @focus.native="onFocus('municipality')"
          @blur.native="onBlur('municipality')"
          :options="sourceSelectionOptions"
          :class="
            submitted && $v.form.municipality.$error
              ? 'is-invalid mb-1 rounded vgs-form-select'
              : 'mb-4 rounded vgs-form-select'
          "
        >
        </b-form-select>
        <label class="vgs-ac-label" for="fm_municipality" ref="municipality_label">{{
          labels.Municipality + ' *'
        }}</label>
        <div class="vgs-select-chevron">
          <b-icon icon="chevron-down"></b-icon>
        </div>
        <div v-if="submitted && $v.form.municipality.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.municipality.required">{{ labels.CommunityDropdownRequired }}</span>
        </div>
      </div>

      <div class="position-relative" ref="salutation">
        <b-form-select
          id="fm_salutation"
          :aria-label="labels.Salutation"
          ref="salutation_form-control"
          @blur.native="onBlur('salutation')"
          @focus.native="onFocus('salutation')"
          v-model="form.salutation"
          class="mb-4 rounded vgs-form-select"
        >
          <b-form-select-option value="1">Frau</b-form-select-option>
          <b-form-select-option value="2">Herr</b-form-select-option>
        </b-form-select>
        <label class="vgs-ac-label" for="fm_salutation" ref="salutation_label">{{ labels.Salutation }}</label>
        <div class="vgs-select-chevron">
          <b-icon icon="chevron-down"></b-icon>
        </div>
      </div>

      <div class="position-relative" ref="firstname">
        <b-form-input
          id="fm_firstname"
          :aria-label="labels.FirstName"
          type="text"
          @blur.native="onBlur('firstname')"
          @focus.native="onFocus('firstname')"
          v-model="form.firstname"
          ref="firstname_form-control"
          :class="
            submitted && $v.form.firstname.$error
              ? 'is-invalid mb-1 rounded vgs-form-select'
              : 'mb-4 rounded vgs-form-select'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_firstname" ref="firstname_label">{{ labels.FirstName + ' *' }}</label>
        <div v-if="submitted && $v.form.firstname.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.firstname.required">{{ labels.FirstnameRequired }}</span>
        </div>
      </div>

      <div class="position-relative" ref="lastname">
        <b-form-input
          id="fm_lastname"
          @blur.native="onBlur('lastname')"
          @focus.native="onFocus('lastname')"
          :aria-label="labels.LastName"
          type="text"
          v-model="form.lastname"
          ref="lastname_form-control"
          :class="
            submitted && $v.form.lastname.$error
              ? 'is-invalid mb-1 rounded vgs-form-select'
              : 'mb-4 rounded vgs-form-select'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_lastname" ref="lastname_label">{{ labels.LastName + ' *' }}</label>
        <div v-if="submitted && $v.form.lastname.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.lastname.required" style="width: 100%; text-align: left">{{
            labels.SurnameRequired
          }}</span>
        </div>
      </div>

      <b-row>
        <b-col cols="4 pr-1">
          <div class="position-relative" ref="academictitle">
            <b-form-input
              id="fm_academictitle"
              ref="academictitle_form-control"
              :aria-label="labels.AcademicTitle"
              maxlength="50"
              type="text"
              v-model="form.academictitle"
              class="mb-4 rounded vgs-form-input nocontrols"
              @blur.native="onBlur('academictitle')"
              @focus.native="onFocus('academictitle')"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_academictitle">{{ labels.AcademicTitle }}</label>
          </div>
        </b-col>
        <b-col cols="8 pl-1">
          <div class="position-relative" ref="postnominaltitles">
            <b-form-input
              id="fm_postnominaltitles"
              ref="postnominaltitles_form-control"
              :aria-label="labels.PostNominalTitles"
              maxlength="50"
              type="text"
              v-model="form.postnominaltitles"
              @blur.native="onBlur('postnominaltitles')"
              @focus.native="onFocus('postnominaltitles')"
              class="mb-4 rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_postnominaltitles">{{ labels.PostNominalTitles }}</label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8 pr-1">
          <div class="position-relative" ref="street">
            <b-form-input
              id="fm_street"
              ref="street_form-control"
              :aria-label="labels.Street"
              maxlength="50"
              type="text"
              v-model="form.street"
              @blur.native="onBlur('street')"
              @focus.native="onFocus('street')"
              class="mb-4 rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_street">{{ labels.Street }}</label>
          </div>
        </b-col>
        <b-col cols="4 pl-1">
          <div class="position-relative" ref="streetNumber">
            <b-form-input
              id="fm_streetnumber"
              ref="streetNumber_form-control"
              :aria-label="labels.StreetNumber"
              maxlength="50"
              type="text"
              v-model="form.streetnumber"
              @blur.native="onBlur('streetNumber')"
              @focus.native="onFocus('streetNumber')"
              class="mb-4 rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_streetnumber">{{ labels.StreetNumber }}</label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6 pr-1">
          <div class="position-relative" ref="staircase">
            <b-form-input
              id="fm_staircase"
              ref="staircase_form-control"
              :aria-label="labels.Staircase"
              maxlength="50"
              type="text"
              v-model="form.staircase"
              @blur.native="onBlur('staircase')"
              @focus.native="onFocus('staircase')"
              class="mb-4 rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_staircase">{{ labels.Staircase }}</label>
          </div>
        </b-col>
        <b-col cols="6 pl-1">
          <div class="position-relative" ref="apartmentnumber">
            <b-form-input
              id="fm_apartmentnumber"
              ref="apartmentnumber_form-control"
              :aria-label="labels.ApartmentNumber"
              maxlength="50"
              type="text"
              v-model="form.apartmentnumber"
              @blur.native="onBlur('apartmentnumber')"
              @focus.native="onFocus('apartmentnumber')"
              class="mb-4 rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_apartmentnumber">{{ labels.ApartmentNumber }}</label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4 pr-1">
          <div class="position-relative" ref="postalcode">
            <b-form-input
              id="fm_postalcode"
              ref="postalcode_form-control"
              @keydown="checkLength"
              :aria-label="labels.PostalCode"
              type="number"
              v-model="form.postalcode"
              class="mb-4 rounded vgs-form-input nocontrols"
              @blur.native="onBlur('postalcode')"
              @focus.native="onFocus('postalcode')"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_postalcode">{{ labels.PostalCode }}</label>
          </div>
        </b-col>
        <b-col cols="8 pl-1">
          <div class="position-relative" ref="city">
            <b-form-input
              id="fm_city"
              aria-label="labels.City"
              ref="city_form-control"
              type="text"
              maxlength="50"
              v-model="form.city"
              @blur.native="onBlur('city')"
              @focus.native="onFocus('city')"
              class="mb-4 rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_city">{{ labels.City }}</label>
          </div>
        </b-col>
      </b-row>
      <div class="position-relative" ref="dateofbirth">
        <b-form-input
          id="fm_dateofbirth"
          ref="dateofbirth_form-control"
          :aria-label="labels.DateOfBirth"
          type="text"
          maxlength="10"
          @focus.native="onFocus('dateofbirth')"
          @blur.native="onBlur('dateofbirth')"
          required
          v-model="form.dateofbirth"
          :class="
            submitted && $v.form.dateofbirth.$error
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'mb-4 rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_dateofbirth">{{ labels.DateOfBirth + ' (TT.MM.JJJJ)' }}</label>
        <div v-if="$v.form.dateofbirth.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.dateofbirth.isDate">{{ labels.SignUpNPFormBirthDateNotValid }}</span>
        </div>
      </div>
      <div class="position-relative" ref="email">
        <b-form-input
          id="fm_email"
          ref="email_form-control"
          :aria-label="labels.EMail"
          type="email"
          maxlength="50"
          @focus.native="onFocus('email')"
          @blur.native="onBlur('email')"
          required
          v-model="form.email"
          :class="
            submitted && $v.form.email.$error ? 'is-invalid mb-1 rounded vgs-form-input' : 'mb-3 rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_email">{{ labels.EMail + ' *' }}</label>
        <div v-if="$v.form.email.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.email.required">{{ labels.EmailRequired }}</span>
          <span v-if="!$v.form.email.email && $v.form.email.required">{{ labels.EmailInvalidFormat }}</span>
        </div>
      </div>
      <div class="mb-3 text-left vgs-font-small">
        {{ labels.PasswordComplexityInfo }}
      </div>
      <div class="position-relative" ref="password">
        <b-form-input
          id="fm_password"
          ref="password_form-control"
          :aria-label="labels.Password"
          v-bind:type="this.passwordType"
          required
          maxlength="20"
          v-model="form.password"
          v-on:keyup="set_password_complexity_level_handler"
          @focus.native="onFocus('password')"
          @blur.native="onBlur('password')"
          :class="
            submitted && $v.form.password.$error
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'mb-3 rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_password">{{ labels.Password + ' *' }}</label>
        <div class="vgs-pw-eye" @click="fm_password_switch()">
          <IconSet icon="eye" state=""></IconSet>
        </div>
        <div v-if="$v.form.password.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.password.required">{{ labels.PasswordRequired }}</span>
        </div>
      </div>

      <div :class="!$v.form.password.$error && $v.form.password.required ? 'mb-5' : 'mb-3'">
        <PasswordComplexity
          v-if="!$v.form.password.$error && $v.form.password.required"
          v-bind:level="this.password_complexity_level"
        ></PasswordComplexity>
      </div>

      <div class="position-relative" ref="passwordconfirmation">
        <b-form-input
          id="fm_passwordconfirmation"
          ref="passwordconfirmation_form-control"
          :aria-label="labels.PasswordConfirmation"
          type="password"
          required
          maxlength="20"
          @focus.native="onFocus('passwordconfirmation')"
          @blur.native="onBlur('passwordconfirmation')"
          v-model="form.passwordconfirmation"
          :class="
            submitted && $v.form.passwordconfirmation.$error
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'mb-4 rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_passwordconfirmation">{{ labels.PasswordConfirmation + ' *' }}</label>
        <div v-if="$v.form.passwordconfirmation.$error" class="invalid-feedback error-class">
          <span v-if="!$v.form.passwordconfirmation.required">{{ labels.ConfirmPasswordRequired }}</span>
          <span v-if="!$v.form.passwordconfirmation.sameAsPassword && $v.form.passwordconfirmation.required">{{
            labels.ConfirmPasswordNotMatched
          }}</span>
        </div>
      </div>
      <div class="mb-3 text-left vgs-font-small"  >
        <span v-html="ElectronicDocumentDeliveryInfoText"></span>
      </div>
      <div class="vgs-error-messages" v-html="get_error_messages"></div>
      <b-button
        :aria-label="labels.SignUp"
        type="submit"
        variant="primary"
        @click="fm_submit"
        :disabled="form.submitDisabled"
        class="w-100 mt-2 p-3 rounded vgs-form-button"
        >{{ labels.SignUp }}</b-button
      >
      <div class="vcs-required">* {{ labels.RequiredField }}</div>
    </b-form>
    <div v-if="!isShow" class="successful-register">
      <div class="vgs-error-messages" v-html="get_error_messages"></div>
    </div>
  </div>
</template>
<script>
import PasswordComplexity from '@/components/PasswordComplexity.vue';
import IconSet from '@/structure/IconSet.vue';

import { vcm } from '@/mixins/vcm';
import { vvm } from '@/mixins/vvm';
import { customErrors } from '@/mixins/customErrors';
import { required, email, sameAs } from 'vuelidate/lib/validators';
export default {
  mixins: [vcm, vvm, customErrors],
  props: ['ElectronicDocumentDeliveryInfoText'],
  components: {
    PasswordComplexity,
    IconSet,
  },

  name: 'SignUpNPForm',
  data() {
    return {
      isSuccess: false,
      isShow: true,
      labels: {
        Municipality: this.get_scope_translation('Municipality', 'label'),
        Salutation: this.get_scope_translation('Salutation', 'label'),
        FirstName: this.get_scope_translation('FirstName', 'label'),
        LastName: this.get_scope_translation('LastName', 'label'),
        AcademicTitle: this.get_scope_translation('AcademicTitle', 'label'),
        PostNominalTitles: this.get_scope_translation('PostNominalTitles', 'label'),
        Street: this.get_scope_translation('Street', 'label'),
        StreetNumber: this.get_scope_translation('StreetNumber', 'label'),
        ApartmentNumber: this.get_scope_translation('ApartmentNumber', 'label'),
        PostalCode: this.get_scope_translation('PostalCode', 'label'),
        City: this.get_scope_translation('City', 'label'),
        DateOfBirth: this.get_scope_translation('DateOfBirth', 'label'),
        EMail: this.get_scope_translation('EMail', 'label'),
        Password: this.get_scope_translation('Password', 'label'),
        PasswordConfirmation: this.get_scope_translation('PasswordConfirmation', 'label'),
        SignUp: this.get_scope_translation('SignUp', 'label'),
        RequiredField: this.get_scope_translation('RequiredField', 'label'),
        PasswordComplexityInfo: this.get_scope_translation('PasswordComplexityInfo', 'label'),
        AdditionalInfo: this.get_scope_translation('AdditionalInfo', 'label'),
        Staircase: this.get_scope_translation('Staircase', 'label'),
        CommunityDropdownRequired: this.get_scope_translation('CommunityDropdownRequired', 'label'),
        FirstnameRequired: this.get_scope_translation('FirstnameRequired', 'label'),
        SurnameRequired: this.get_scope_translation('SurnameRequired', 'label'),
        DateofBirthRequired: this.get_scope_translation('DateofBirthRequired', 'label'),
        SignUpNPFormBirthDateNotValid: this.get_scope_translation('SignUpNPFormBirthDateNotValid', 'label'),
        EmailRequired: this.get_scope_translation('EmailRequired', 'label'),
        EmailInvalidFormat: this.get_scope_translation('EmailInvalidFormat', 'label'),
        PasswordRequired: this.get_scope_translation('PasswordRequired', 'label'),
        PasswordNotStrong: this.get_scope_translation('PasswordNotStrong', 'label'),
        ConfirmPasswordRequired: this.get_scope_translation('ConfirmPasswordRequired', 'label'),
        ConfirmPasswordNotMatched: this.get_scope_translation('ConfirmPasswordNotMatched', 'label'),
      },
      form: {
        municipality: '',
        salutation: '',
        firstname: '',
        lastname: '',
        academictitle: '',
        postnominaltitles: '',
        street: '',
        streetnumber: '',
        apartmentnumber: '',
        postalcode: '',
        city: '',
        dateofbirth: '',
        email: '',
        password: '',
        passwordconfirmation: '',
        staircase: '',
        submitDisabled: false,
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      municipality: { required },
      firstname: { required },
      lastname: { required },
      email: { required, email },
      dateofbirth: {
        isDate(dateofbirth) {
          const regex = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
          return regex.test(dateofbirth) || dateofbirth.length == 0;
        },
      },
      password: {
        required,
        // isPasswordStrong(password) {
        //         const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        //         return regex.test(password);
        // }
      },
      passwordconfirmation: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  mounted() {
    const FiledLabels = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'SignUpNPForm');
    Object.assign(this.labels, FiledLabels);
  },
  computed: {
    sourceSelectionOptions() {
      return this.$store.state.initialize.sourceSelectionOptions;
    },
  },
  methods: {
    onBlur(selectElement) {
      let element = this.$refs[selectElement];
      let inputElement = this.$refs[selectElement + '_form-control'];
      if (selectElement === 'dateofbirth') {
        this.$v.form.dateofbirth.$touch();
      }
      if (selectElement === 'email') {
        this.$v.form.email.$touch();
      }
      if (selectElement === 'password') {
        this.$v.form.password.$touch();
      }
      if (selectElement === 'passwordconfirmation') {
        this.$v.form.passwordconfirmation.$touch();
      }
      if (element.classList.contains('onfocus') && !inputElement.value) {
        element.classList.remove('onfocus');
      }
    },
    onFocus(selectElement) {
      let element = this.$refs[selectElement];
      if (!element.classList.contains('onfocus')) {
        element.className += ' onfocus';
      }
    },
    checkLength($event) {
      if (this.form.postalcode.length > 3 && $event.keyCode !== 8 && $event.keyCode !== 9 && $event.keyCode !== 69)
        $event.preventDefault();
    },
    fm_submit(evt) {
      evt.preventDefault();

      // Call //
      this.submitted = true;
      var fieldsValidated = true;
      this.form.submitDisabled = true;
      this.$v.$touch();
      if (this.$v.form.$invalid || this.password_complexity_level < 4) {
        this.form.submitDisabled = false;
        fieldsValidated = false;
      }

      var signUpSet = {
        SourceUUID: this.form.municipality,
        Salutation: this.form.salutation ? parseInt(this.form.salutation) : 0,
        FirstName: this.form.firstname,
        Middlename: '',
        LastName: this.form.lastname,
        AcademicTitles: this.form.academictitle,
        PostNominalTitles: this.form.postnominaltitles,
        MainAddress: {
          TypeName: 'Delivery',
          Street: this.form.street,
          StreetNumber: this.form.streetnumber,
          Staircase: this.form.staircase,
          ApartmentNumber: this.form.apartmentnumber,
          PostalCode: this.form.postalcode,
          City: this.form.city,
          Country: 1,
          Phone: '',
          EMail: '',
        },
        DateOfBirth: '',
        UserSignIn: {
          TypeName: 'User',
          StateName: 'Created',
          EMail: this.form.email,
          Password: this.form.password,
        },
      };

      if (fieldsValidated) {
        var myDateOfBirth = '';
        if (this.form.dateofbirth.trim()) {
          var dateString = this.form.dateofbirth;
          var parts = dateString.split('.');
          var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
          myDateOfBirth = mydate.toDateString();
        }
        signUpSet.DateOfBirth = myDateOfBirth;
        this.$root.data_access.setIdentifier('sign.up.form');
        this.$root.data_access.setReceiver('component', this);
        this.$root.data_access.setRequestBody(signUpSet);
        this.$root.data_access.call('signup_natural');
      }
    },
    onSuccessHandler() {
      this.$emit('onSuccess', this.isShow);
    },
    showError(response) {
      switch (response.responseCode) {
        case '201':
          this.isShow = false;
          this.onSuccessHandler();
          this.isSuccess = true;
          this.add_error_message(this.success.RegisterSuccess, this.isSuccess);
          break;
        case '409':
          this.form.submitDisabled = false;
          this.add_error_message(this.errors.SignUpNPFormUserAlreadyExists, this.isSuccess);
          break;
        case '400':
          this.form.submitDisabled = false;
          this.add_error_message(this.errors.SignUpNPFormBirthDateNotValid, this.isSuccess);
          break;
        default:
          this.add_error_message(response.responseMessage, this.isSuccess);
          break;
      }
    },

    receive(dataObject) {
      if (dataObject) {
        switch (dataObject.identifier) {
          case 'sign.up.form':
            if (dataObject.data) {
              this.showError(dataObject.data);
              this.isdisabled = false;
            }
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vcs-required {
  width: 100%;
  text-align: right;
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #121212;
}
.error-class {
  font-size: 17px;
  text-align: left;
  margin-bottom: 13px;
}
.error-class > span {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.vgs-form-indent form .form-control.is-invalid {
  background-image: none !important;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.dropdown-icon-top {
  top: 33px !important;
}

.form-field__control > .vgs-select-chevron {
  top: 30px;
}

.position-relative > label {
  display: block;
  position: absolute;
  text-align: left;
  top: 7px;
  left: 14px;
  font-size: 16px;
  transition: all 0.3s;
}

.onfocus > input + label,
.onfocus > select + label {
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-webkit-transition: 0.3s all ease-in-out;
  top: -18px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
}

.successful-register .vgs-error-messages {
  margin-top: 1.5rem;
  background-color: #ffdddd !important;
  padding: 30px 20px;
  text-align: center;
  border: 1px solid #f44336;
  margin: 50px auto 50px;
}
.successful-register .vgs-error-messages ul {
  margin-bottom: 0;
}
.successful-register .vgs-error-messages ul li {
  text-align: center;
  margin-bottom: 0;
}
</style>
